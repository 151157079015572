<template>
  <portal
    v-if="customer"
    to="dialogs"
  >
    <PDialog
      visible="visible"
      :title="dialogTitle"
      @close="$emit('close')"
      classes="left-0 md:left-auto md:w-4/5 lg:w-3/5"
    >
      <PDialogContent v-if="loaded">
        <div class="flex flex-wrap pb-10">
          <div
            v-if="!locationId"
            class="w-full"
          >
            <div class="w-full mt-4 md:flex md:space-x-4">
              <div class="md:w-1/2">
                <PInput
                  class="w-full"
                  :label="$tk('LocationDialog.LocationName')"
                  :required="true"
                  :disabled="isSubmitting"
                  v-model="location.name"
                />
              </div>
              <div class="md:w-1/2">
                <PInput
                  class="w-full"
                  :required="true"
                  :disabled="isSubmitting"
                  v-model="location.deliveryStreet"
                  :label="$tk('Common.Address.Street')"
                />
              </div>
            </div>
            <div class="w-full mt-4 md:flex md:space-x-4">
              <div class="md:w-1/2">
                <PInput
                  :required="true"
                  :disabled="isSubmitting"
                  v-model="location.deliveryPostalCode"
                  :label="$tk('Common.Address.PostCode')"
                />
              </div>
              <div class="md:w-1/2">
                <PInput
                  :required="true"
                  :disabled="isSubmitting"
                  v-model="location.deliveryPostalName"
                  :label="$tk('Common.Address.PostPlace')"
                />
              </div>
            </div>
            <div class="w-full mt-4 md:flex md:space-x-4">
              <div class="md:w-1/2"></div>
              <div class="md:w-1/2">
                <PCountrySelect
                  :disabled="isSubmitting"
                  v-model="location.deliveryCountry"
                  class="w-full"
                />
              </div>
            </div>
            <hr class="w-full mt-4 border-2 border-b-0" />
          </div>
          <div class="w-full mt-4 md:flex md:space-x-4">
            <div class="md:w-1/2">
              <PTextarea
                :disabled="isSubmitting"
                :label="$tk('Common.Order.LoadText')"
                v-model="location.loadText"
              />
              <div
                v-if="locationId"
                class="mt-3 text-xs"
              >
                <span v-html="$tk('LocationDialog.DeliveryBetween')"></span>:
                {{ location.timeReceiveFrom | time }} -
                {{ location.timeReceiveTo | time }}
              </div>
            </div>
            <div class="md:w-1/2">
              <PTextarea
                :disabled="isSubmitting"
                :label="$tk('Common.Order.TransportText')"
                v-model="location.transportText"
              />
            </div>
          </div>
          <div class="w-full mt-4 md:flex md:space-x-4">
            <div class="md:w-1/2">
              <PSelect
                :label="$t('Common.Location.NeedsLift')"
                :disabled="isSubmitting"
                :items="needsLiftItems"
                :required="true"
                v-model="needsLift"
              >
              </PSelect>
            </div>
            <div class="md:w-1/2">
              <PSelect
                :label="$t('Common.Location.HasRamp')"
                :disabled="isSubmitting"
                :items="hasRampItems"
                :required="true"
                v-model="hasRamp"
              >
              </PSelect>
            </div>
          </div>
          <div class="w-full mt-4 md:flex md:space-x-4">
            <div class="md:w-1/2">
              <PTrucktype
                :disabled="isSubmitting"
                v-model="location.truckTypeId"
              />
              <PInput
                v-if="location.returnDays > 0"
                :disabled="isSubmitting"
                :label="$tk('LocationDialog.EmailReturnOrder')"
                v-model="location.emailReturnOrder"
              />
            </div>

            <div class="md:w-1/2">
              <PInput
                :disabled="isSubmitting"
                :label="$tk('LocationDialog.ExternalId')"
                v-model="location.externalId"
              />

              <PInputGln
                class="mt-4"
                label="GLN"
                :disabled="isSubmitting"
                v-model="location.gln"
                @valid="valid"
              />
            </div>
          </div>

          <div class="w-full mt-4 md:flex md:space-x-4">
            <div class="md:w-1/2">
              <PInput
                :disabled="isSubmitting"
                type="email"
                :label="$tk('LocationDialog.EmailPurchase')"
                :error="!validEmailPurchase && showEmailErrors ? 'Ugyldig e-post' : null"
                v-model="location.emailPurchase"
              />
            </div>

            <div class="md:w-1/2">
              <PInput
                type="email"
                :disabled="isSubmitting"
                :label="$tk('LocationDialog.EmailNotificationOrderConfirmed')"
                :error="!validEmailOrder && showEmailErrors ? 'Ugyldig e-post' : null"
                v-model="location.emailOrderConfirmed"
              />
            </div>
          </div>

          <div
            class="w-full mt-8 text-sm"
            v-if="locationId"
          >
            <div>
              <span
                class=""
                v-html="$tk('LocationDialog.DeliveryAddresses')"
              ></span>
              <a
                class="float-right text-sm font-normal text-green-500 underline"
                @click="onShowAddressForm"
                href="#"
              >
                {{
                  addressFormVisible
                    ? $tk("Common.Actions.Close")
                    : $tk("Common.Actions.Add")
                }}
              </a>
            </div>
            <transition
              enter-active-class="transform ease-out duration-300 transition"
              enter-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition ease-in duration-100"
              leave-class="opactity-100"
              leave-to-class="opacity-0"
            >
              <div
                v-if="addressFormVisible"
                class="p-5 mt-4 bg-green-100 rounded-lg sm:p-4"
              >
                <AddressForm
                  ref="addressForm"
                  @success="onAddressFormSuccess"
                  @close="onAddressFormClose"
                  :locationId="locationId"
                  :address="selectedAddress"
                ></AddressForm>
              </div>
            </transition>

            <div class="p-3 mt-4 bg-gray-200 rounded-lg">
              <AddressList
                @deleted="addressRemoved"
                @edit="onEditAddress"
                :addresses="sortedAddresses"
                :activeAddressId="selectedAddress?.id"
              />
            </div>
          </div>
        </div>
      </PDialogContent>
      <PDialogActions>
        <PButton
          color="secondary"
          :disabled="isSubmitting"
          @click="$emit('close')"
        >
          <span v-html="$tk('LocationDialog.Abort')"></span>
        </PButton>

        <PButton
          class="ml-2"
          v-if="locationId"
          @click="submitCreateLocation"
          :loading="isSubmitting"
        >
          <!-- submitUpdateLocation -->
          <span v-html="$tk('LocationDialog.UpdateLocation')"></span>
        </PButton>

        <PButton
          class="ml-2"
          v-else
          @click="submitCreateLocation"
          :disabled="!canCreate"
          :loading="isSubmitting"
        >
          <span v-html="$tk('LocationDialog.AddLocation')"></span>
        </PButton>
      </PDialogActions>
    </PDialog>
  </portal>
</template>

<script>
import { mapGetters } from "vuex";
import http from "@/utilities/http";
import PTrucktype from "../../components/PTrucktype";
import PCountrySelect from "../../components/PCountrySelect";
import PTextarea from "@/components/forms/PTextarea";
import AddressForm from "../../components/AddressForm";
import AddressList from "../../components/AddressList";
import { pick, first, filter } from "lodash";

export default {
  name: "LocationDialog",
  props: {
    locationId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      location: {
        name: "",
        deliveryPostalCode: "",
        deliveryPostalName: "",
        deliveryCountry: "NOR",
        deliveryStreet: "",
        trucktypeId: "STD17",
        loadText: "",
        transportText: "",
        returnDays: 0,
        emailReturnOrder: "",
        emailPurchase: "",
        emailOrderConfirmed: "",
        gln: "",
        needsLift: undefined,
        hasRamp: undefined
      },
      countries: [],
      glnValid: false,
      loaded: false,
      addressFormVisible: false,
      selectedAddress: null,
      isSubmitting: false,
      showEmailErrors: false
    };
  },
  components: {
    AddressForm,
    AddressList,
    PTrucktype,
    PCountrySelect,
    PTextarea,
  },
  computed: {
    ...mapGetters(["customer"]),

    dialogTitle() {
      return this.locationId
        ? `${this.location.name} (${this.location.id})`
        : this.$tk("LocationDialog.AddLocation");
    },

    validEmailPurchase() {
      return this.location.emailPurchase === '' ? true : /^[a-zA-Z0-9][-a-zA-Z0-9._]*@[-a-zA-Z0-9._]+[.][a-zA-Z0-9]{2,4}/.test(this.location.emailPurchase)
    },
    validEmailOrder() {
      return this.location.emailOrderConfirmed === '' ? true : /^[a-zA-Z0-9][-a-zA-Z0-9._]*@[-a-zA-Z0-9._]+[.][a-zA-Z0-9]{2,4}/.test(this.location.emailOrderConfirmed)
    },

    canCreate() {
      return (
        this.location.name != "" &&
        this.location.deliveryStreet != "" &&
        this.location.deliveryPostalCode != "" &&
        this.location.deliveryPostalName &&
        this.location.hasRamp !== undefined &&
        this.location.needsLift !== undefined
      );
    },

    locationArguments() {
      return {
        id: this.location.id,
        customerId: this.customer.id,
        name: this.location.name,
        deliveryStreet: this.location.deliveryStreet,
        deliveryPostalName: this.location.deliveryPostalName,
        deliveryPostalCode: this.location.deliveryPostalCode,
        deliveryCountry: this.location.deliveryCountry,
        nlpStock: this.location.nlpStock,
        capacity: this.location.capacity,
        truckTypeId: this.location.truckTypeId,
        emailPurchase: this.location.emailPurchase,
        emailOrderConfirmed: this.location.emailOrderConfirmed,
        emailReturnOrder: this.location.emailReturnOrder,
        gln: this.location.gln,
        externalId: this.location.externalId,
        loadText: this.location.loadText,
        transportText: this.location.transportText,
        needsLift: this.location.needsLift,
        hasRamp: this.location.hasRamp,
      };
    },

    sortedAddresses() {
      return this.location.addresses;
    },

    needsLiftItems() {
      let arr = [
        { value: "0", text: this.$t("Common.General.No") },
        { value: "1", text: this.$t("Common.General.Yes") },
      ];
      if (this.location.needsLift === null) {
        arr.unshift({ value: "", text: this.$t("Common.General.NotSelected") });
      }
      return arr;
    },

    hasRampItems() {
      let arr = [
        { value: "0", text: this.$t("Common.General.No") },
        { value: "1", text: this.$t("Common.General.Yes") },
      ];
      if (this.location.hasRamp === null) {
        arr.unshift({ value: "", text: this.$t("Common.General.NotSelected") });
      }
      return arr;
    },

    needsLift: {
      get() {
        return this.location.needsLift === true
          ? "1"
          : this.location.needsLift === false
            ? "0"
            : "";
      },
      set(value) {
        this.location.needsLift =
          value === "1" ? true : value === "0" ? false : null;
      },
    },

    hasRamp: {
      get() {
        return this.location.hasRamp === true
          ? "1"
          : this.location.hasRamp === false
            ? "0"
            : "";
      },
      set(value) {
        this.location.hasRamp =
          value === "1" ? true : value === "0" ? false : null;
      },
    },
  },
  watch: {
    addressFormVisible: function(val) {
      if (val) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.addressForm.$el.scrollIntoView({ behavior: "smooth" });
          }, 310);
        });
      }
    },
  },
  methods: {
    onShowAddressForm() {
      this.selectedAddress = null;
      this.addressFormVisible = !this.addressFormVisible;
    },

    async onAddressFormSuccess() {
      this.addressFormVisible = false;
      const location = await http.get("location", {
        params: { id: this.locationId },
      });
      this.location.addresses = location.addresses;
      this.selectedAddress = null;
    },

    onEditAddress(address) {
      this.selectedAddress = address;
      this.addressFormVisible = true;
    },

    onAddressFormClose() {
      this.selectedAddress = null;
      this.addressFormVisible = false;
    },

    addressRemoved(id) {
      this.addressFormVisible = false;
      this.location.addresses = filter(
        this.location.addresses,
        (element) => id !== element.id
      );
    },

    valid(value) {
      this.glnValid = value;
    },

    formatPrimaryAddress(location) {
      let adr = first(location.addresses);
      if (adr !== undefined) {
        return (
          adr.street +
          ", " +
          adr.postalCode +
          " " +
          adr.postalName +
          ", " +
          adr.countryId
        );
      }
      return "";
    },

    async submitCreateLocation() {
      if (!this.validEmailOrder || !this.validEmailPurchase) {
        this.showEmailErrors = true
        return
      }

      this.isSubmitting = true;

      try {
        const location = await http.post("location", this.locationArguments);

        this.$emit("created", {
          address: this.formatPrimaryAddress(location),
          ...pick(location, "id", "name", "typeCode"),
        });

        // if user edits current location and immediately tries to create an order
        if (this.$store.state.status.locationId === location.id) {
          this.$store.dispatch("updateLocation");
        }

        this.$emit("updated");
        this.$emit("close");
      } catch (e) {
        this.$notifyError(
          this.$tk("LocationDialog.ErrorSavingLocation", true),
          this.$formatApiError(e)
        );
        this.isSubmitting = false;
      }

      this.isSubmitting = false;
    },
  },
  async created() {
    if (this.locationId) {
      this.location = await http.get("location", {
        params: { id: this.locationId },
      });
    }
    this.loaded = true;
  },
};
</script>

<style lang="scss" scoped></style>