<template>
  <PDialog
    title="Logo"
    classes="w-2/5"
    @close="$emit('close')"
  >
    <PDialogContent>

      <input
          ref="input"
          type="file"
          name="image"
          accept="image/*"
          @change="setImage"
        />

        <button
          class="items-center justify-center w-full h-64 text-lg text-gray-700 bg-gray-200 border-4 border-gray-300 rounded-lg transition delay-75 focus:outline-none hover:bg-green-200 hover:border-green-300 hover:text-green-700"
          :class="{ 'hidden': !!imgSrc }"
          @click.prevent="showFileChooser"
          v-html="$tk('LogoDialog.ClickToSelectImage')"
        ></button>

        <div :class="{'hidden': !imgSrc }">

          <vue-cropper
            ref="cropper"
            :aspect-ratio="600 / 200"
            :minContainerHeight="250"
            :src="imgSrc"
            preview=".preview"
          />

          <div class="flex justify-between actions">

            <button @click.prevent="zoom(0.2)" title="Zoom inn">
              <font-awesome-icon :icon="['far', 'search-plus']" />
            </button>

            <button @click.prevent="zoom(-0.2)" title="Zoom ut">
              <font-awesome-icon :icon="['far', 'search-minus']" />
            </button>

            <button @click.prevent="move(-10, 0)" title="Flytt til venstre">
              <font-awesome-icon :icon="['far', 'arrow-left']" />
            </button>

            <button @click.prevent="move(10, 0)" title="Flytt til høyre">
              <font-awesome-icon :icon="['far', 'arrow-right']" />
            </button>

            <button @click.prevent="move(0, -10)" title="Flytt opp">
              <font-awesome-icon :icon="['far', 'arrow-up']" />
            </button>

            <button @click.prevent="move(0, 10)" title="Flytt ned">
              <font-awesome-icon :icon="['far', 'arrow-down']" />
            </button>

            <button @click.prevent="rotate(90)" title="Roter med høyre">
              <font-awesome-icon :icon="['far', 'undo']" flip="horizontal" />
            </button>

            <button @click.prevent="rotate(-90)" title="Roter mot venstre">
              <font-awesome-icon :icon="['far', 'undo']" />
            </button>

            <button @click.prevent="reset" title="Nullstill">
              <font-awesome-icon :icon="['far', 'times']" />
            </button>

          </div>

          <div class="flex justify-center mt-10">
            <div class="preview-area">
              <div class="preview"></div>
            </div>
          </div>

        </div>


    </PDialogContent>

    <PDialogActions>

      <PButton
        @click="$emit('close')"
        color="secondary"
      >
        <span v-html="$tk('Common.Actions.Cancel')"></span>
      </PButton>

      <PButton
        class="ml-4"
        @click="onSubmit"
        :loading="isSubmitting"
        :disabled="!canSave"
      >
        <span v-html="$tk('Common.Actions.Save')"></span>
      </PButton>

    </PDialogActions>
  </PDialog>
</template>

<script>

import http from "@/utilities/http"

import VueCropper from "vue-cropperjs"
import "cropperjs/dist/cropper.css"
import { mapGetters } from "vuex"
import { getConfig } from "@/config"

const config = getConfig();

export default {

  components: {
    VueCropper
  },

  data () {
    return {
      site: location.hostname.split(".").join("_").toUpperCase(),
      imgSrc: "",
      cropImg: "",
      data: null,
      isSubmitting: false
    }
  },

  computed: {

    ...mapGetters(["customer"]),

      imageUploadUrl () {
        return config?.apiUrl + "CustomerLogo"
      },

      canSave () {
        return !!this.imgSrc
      }
  },

  methods: {

    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },

    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },

    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },

    reset () {
      this.imgSrc = ""
    },

    setImage(e) {

      const file = e.target.files[0]

      if (file.type.indexOf('image/') === -1) {
        this.$store.dispatch("notify", {
          type: "negative",
          text: this.$tk("LogoDialog.PleaseSelectAnImageFile", true)
        })
        return
      }

      if (typeof FileReader === 'function') {

        const reader = new FileReader()

        reader.onload = (event) => {
          this.imgSrc = event.target.result
          this.$refs.cropper.replace(event.target.result)
        }

        reader.readAsDataURL(file)

      } else {

        this.$store.dispatch("notify", {
          type: "negative",
          text: this.$tk("LogoDialog.BrowserDoesntSupportImageReading", true)
        })

      }
    },

    showFileChooser() {
      this.$refs.input.click()
    },

    onSubmit () {

      this.isSubmitting = true

      this.$refs.cropper.getCroppedCanvas({
        maxWidth: 1200,
        maxHeight: 600,
        //imageSmoothingEnabled: false
        imageSmoothingQuality: "high"
      }).toBlob(async (blob) => {

        const formData = new FormData()

        formData.append("file", blob)
        formData.append("customerId", this.customer.id)

        const response = await http.post(this.imageUploadUrl, formData)

        this.$store.commit("setCustomerImage", response.path)

        this.isSubmitting = false

        this.$emit("close")

      })
    }
  }
}
</script>

<style scoped>

input[type="file"] {
  display: none;
}


.preview-area {
  width: 407px;
}

.preview {
  width: 100%;
  /* height: calc(372px * (9 / 16)); */
  height: calc(372px * (200 / 600));
  overflow: hidden;
}

.actions button {
  height: theme("height.8");
  width: theme("width.8");
}

.actions button:focus {
  outline: none;
}

.actions button:focus,
.actions button:hover {
  background-color: theme("colors.green.200");
}

</style>
